<template>
    <contentBase>
     404
   </contentBase>
 
   </template>
   
   <script>
   import contentBase from '../components/contentBase';
   
   export default {
     name: 'notFoundView',
     components: {
         contentBase,
     }
   }
   </script>
 
 <style scoped>

</style>